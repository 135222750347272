<template>
  <div>
    <DraftGuide :type="1" /><br />
    <div class="head_section section_g">
      <h2 id="dkBody" class="tit_subdesc">
        <span class="txt_number">문서번호 : {{ docNo }}</span>
        <span class="txt_info">{{
          this.itemData.pdfHistoryResult ? _.unescape(this.itemData.pdfHistoryResult.reqTitle) : ""
        }}</span>
      </h2>
    </div>

    <!-- 결재현황 -->
    <div class="section_g">
      <PayStatus
        v-if="approvalLine.length !== 0"
        :approval-line="approvalLine"
        :doc-no="docNo"
        :doc-flag="docFlag"
      />
      <!-- 결재선 지정 -->
      <DraftApprLine
        v-if="approvalLine.length == 0"
        :isFixedApprovalLine="!$isAdmin ? true : false"
        :init-data="draftApprLineinitData"
        :draftDeptApprLineAlertText="draftDeptApprLineAlertText"
        @updateSelectedDraftDept="onUpdateSelectedDraftDept"
        @updateDraftDeptApprLine="onUpdateDraftDeptApprLine"
        @updateProcessingApprovalLine="onUpdateProcessingApprovalLine"
        @updateReferenceApprovalLine="onUpdateReferenceApprovalLine"
        @selectedApprovalLineAccount="onSelectedApprovalLineAccount"
      />
    </div>
    <!-- 승인 반려 코멘트 -->
    <!-- <RejectComment v-if="whetherExistComment" :approval-line="approvalLine" /> -->
    <!-- 구매요청 기본정보 -->
    <!-- <DraftPurchaseReqeustInfo :draftData="draftData" /> -->
    <!-- 구매요청품목 -->
    <!-- <DraftPurchaseGoodsList
      v-if="model.assetType === assetType.INFRA_ASSET"
      :draftData="draftData" -->

    <!-- 정산정보 -->
    <!-- <DraftPurchasePayment :draftData="draftData" /> -->

    <!-- PdfViewer -->
    <PdfViewer v-if="itemData.targetPk" :pdfInfoObj="itemData.pdfHistoryResult" />

    <!-- 기안정보 -->
    <DraftInfoBasic :model.sync="model" />
    <!-- 첨부파일 -->
    <DraftFile
      :attachFileList.sync="model.attachFileList"
      @fileUploaded="fileUploaded"
      @fileRemoved="fileRemoved"
    />

    <div class="wrap_btn type_bottom">
      <div class="pos_left">
        <button type="button" class="btn_large btn_fourthly" @click.prevent="gotoList">목록</button>
      </div>
      <div class="pos_right">
        <button type="button" class="btn_large btn_secondary" @click.prevent="deleteConfirm">
          삭제
        </button>
        <button
          v-if="isAdmin"
          type="button"
          class="btn_large btn_primary"
          @click.prevent="save(docStatus.PROCESSING)"
        >
          상신
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DraftGuide from "@/_approval/components/shared/write/DraftGuide";
import PayStatus from "@/_approval/components/shared/view/PayStatus";
import RejectComment from "@/_approval/components/shared/view/RejectComment";
import DraftPurchaseReqeustInfo from "@/_approval/components/purchaseDraft/view/DraftPurchaseReqeustInfo";
import DraftPurchaseGoodsList from "@/_approval/components/purchaseDraft/view/DraftPurchaseGoodsList";
import DraftPurchasePayment from "@/_approval/components/purchaseDraft/view/DraftPurchasePayment";
import DraftFile from "@/_approval/components/purchaseDraft/write/DraftFile";
import {
  approvalStatus,
  assetType,
  commConst,
  docStatus,
  targetGubun,
  aprvlLineType,
} from "@/_approval/const/const.js";
import ApiService from "@/services/ApiService";
import commDocs from "@/_approval/mixins/common/commDocs";
// import DraftWriteModel from '@/_approval/pages/payment/write/DraftWriteModel';
import DraftWriteModel from "@/_approval/pages/payment/write/DraftWriteModel";

import LocalStorageManager from "@/LocalStorageManager";
import PdfViewer from "@/components/shared/PdfViewer";
import DraftApprLine from "@/_approval/components/shared/write/DraftApprLine";
import DraftInfoBasic from "@/_approval/components/payment/write/DraftInfoBasic";
import { mapState } from "vuex";
import ApprovalLineModel from "@/_approval/pages/payment/write/ApprovalLineModel";

export default {
  name: "DraftIngDetail",
  components: {
    DraftGuide,
    PayStatus,
    RejectComment,
    DraftPurchaseReqeustInfo,
    DraftPurchaseGoodsList,
    DraftPurchasePayment,
    DraftFile,
    PdfViewer,
    DraftWriteModel,
    DraftApprLine,
    DraftInfoBasic,
  },
  mixins: [commDocs],
  data() {
    return {
      model: new DraftWriteModel(),
      assetType: assetType,
      approvalLine: [],
      docFlag: "DT",
      docNo: this.$route.params.id,
      itemData: {},
      itemId: "",
      targetPk: "",
      docStatus: Object.freeze(docStatus),
      draftApprLineinitData: {},
      draftDeptApprLineAlertText: "",
    };
  },
  computed: {
    ...mapState("CommStore", {
      login: (state) => state.login,
    }),
    isAdmin() {
      return LocalStorageManager.shared.getIsAdmin();
    },
  },
  async created() {
    const userData = LocalStorageManager.shared.getUserData();

    const tokenData = LocalStorageManager.shared.getTokenData();

    const empInfo = userData.empNo;
    const params = {
      empInfo,
      page: 1,
      size: 10,
    };
    const result = await ApiService.shared.get(this.$apiPath.APRVL_ORGEMP, { params: params });
    if (result.code !== "200") {
      alert(res.text);
    }

    this.$store.commit("CommStore/login", {
      empNo: userData.empNo,
      empName: userData.name,
      accountId: userData.username,
      deptName: result.data[0].deptCodeName,
      deptCode: result.data[0].deptCode,
      officeLocation: "",
      isAdmin: this.$isAdmin,
      deptPathName: "",
      email: result.data[0].email,
      mobile: userData.tel == null ? "-" : userData.tel,
    });

    await this.getData();
    await this.updateRead();
  },

  methods: {
    async updateRead() {
      const { docStatus, aprvlStatus } = this.docFlagToCode(this.docFlag);

      const params = {
        targetGubun: targetGubun.STANDARD,
        docNoList: [this.docNo],
        readYn: true,
        docStatus,
        aprvlStatus,
      };

      const res = await ApiService.shared.put(this.$apiPath.APRVL_DOC_READ, params);
    },
    docFlagToCode(flag) {
      // - 진행중 문서 : docStatus 'I'
      // - 완료된 문서 : docStatus 'C'
      // - 반려된 문서 : docStatus 'J'
      //
      // - 결재전 문서 : aprvlStatus 'W'
      // - 수신된 문서 : aprvlStatus 'R'
      // - 진행중 문서 : aprvlStatus 'A'
      // - 완료된 문서 : docStatus 'C', aprvlStatus 'ALL'
      // - 반려된 문서 : docStatus 'J', aprvlStatus 'ALL'
      //
      // - 진행중 문서 : docStatus 'I', aprvlStatus 'N'
      // - 처리된 문서 : docStatus 'C', aprvlStatus 'N'
      switch (flag) {
        case "DT":
          return { docStatus: "T", aprvlStatus: "" };
        case "DI":
          return { docStatus: "I", aprvlStatus: "" };
        case "DC":
          return { docStatus: "C", aprvlStatus: "" };
        case "DR":
          return { docStatus: "J", aprvlStatus: "" };
        case "AB":
          return { docStatus: "", aprvlStatus: "W" };
        case "AE":
          return { docStatus: "", aprvlStatus: "R" };
        case "AEW":
          return { docStatus: "", aprvlStatus: "R" };
        case "AI":
          return { docStatus: "", aprvlStatus: "A" };
        case "AC":
          return { docStatus: "C", aprvlStatus: "ALL" };
        case "AR":
          return { docStatus: "J", aprvlStatus: "ALL" };
        case "RI":
          return { docStatus: "I", aprvlStatus: "N" };
        case "RC":
          return { docStatus: "C", aprvlStatus: "N" };
      }
    },
    async getData() {
      this.itemId = this.$route.params.id;
      const path = `${this.$apiPath.APRVL_DOC_LIST}/STD/${this.itemId}`;
      // this.$store.dispatch(PROGRESS_START_ACTION);
      const result = await ApiService.shared.getData(path);
      const { data } = result;

      this.itemData = data;
      this.model.setData(data);
      this.docNo = this.itemData.docNo;
      return;
    },

    popUpCancelLayer() {
      this._showLayer({ type: "confirm", content: "신청을 취소하시겠습니까?" }, this.cancelDraft);
    },
    async cancelDraft() {
      const res = await ApiService.shared.put(this.$apiPath.APRVL_DOC_LIST, {
        docStatus: docStatus.TEMPORARY,
        targetGubun: targetGubun.STANDARD,
        targetPk: this.model.targetPk,
      });

      if (!this.checkNormalResponse(res)) {
        return;
      }

      await this.$router.push(this.$paymentRouterPath.PAYMENT_DRAFT_TEMP);
    },
    gotoList() {
      this.$router.push(this.$paymentRouterPath.PAYMENT_DRAFT);
    },
    async onClickAnnounceRegister() {
      const number = await this.getNewAnnounceNumber();
      const path = `${this.$routerPath.ANNOUNCE_WRITE}/${number}?requestCid=${this.docNo}`;
      this.$router.push(path);
      // this.movePageWithPath(`${this.$routerPath.ANNOUNCE_WRITE}/${number}?requestCid=${this.docNo}`);
    },
    async getNewAnnounceNumber() {
      const path = this.$apiPath.CREATE_ANNOUNCE_NUMBER;
      const result = await ApiService.shared.getData(path);
      if (!result.data) return null;

      const newNumber = result.data.postCid;
      return newNumber;
    },

    async save(docStatus) {
      const errorMsg = this.model.validate(docStatus === this.docStatus.TEMPORARY);
      if (errorMsg) {
        this._showLayer({ type: "alert", content: errorMsg });
        return;
      }
      this.model.docStatus = docStatus;
      this.model.deptName = this.login.deptName;
      this.model.email = this.login.email;
      this.model.mobile = this.login.mobile;
      this.model.myDraftDeptInfo = new ApprovalLineModel(
        this.login.empNo,
        this.login.deptCode,
        aprvlLineType.DRAFT,
        "",
        true,
      );

      const formData = this.model.makeFormData();
      const menuId = this.$route.meta.menuId;
      const docNo = this.$route.params.id;

      let res;

      // return;

      if (menuId && docNo) {
        formData.docNo = docNo;
        res = await ApiService.shared.put(this.$apiPath.APRVL_DOC_LIST, formData);
      } else {
        res = await ApiService.shared.post(this.$apiPath.APRVL_DOC_LIST, formData);
      }

      if (res.code !== "200") {
        this._showLayer({
          type: "alert",
          content: res.text || "처리중 에러가 발생하였습니다. 재시도해주세요.",
        });
        return;
      }
      this.routeOut = true;

      if (docStatus === this.docStatus.PROCESSING) {
        this.$router.push(this.$paymentRouterPath.PAYMENT_DRAFT_ING);
        this._showLayer({ type: "alert", content: "상신이 완료되었습니다." });
      }
    },
    async onUpdateSelectedDraftDept(selectedDeptCode) {
      this.model.deptPathName = await this.fetchDeptPathNameByDeptCode(selectedDeptCode);
      this.model.deptCode = selectedDeptCode;
    },
    onUpdateDraftDeptApprLine(draftDeptApprLine) {
      this.model.draftDeptApprLine = draftDeptApprLine;
    },
    onUpdateProcessingApprovalLine(processingApprovalLine) {
      this.model.processingApprovalLine = processingApprovalLine;
    },
    onUpdateReferenceApprovalLine(referenceApprovalLine) {
      this.model.referenceApprovalLine = referenceApprovalLine;
    },
    onSelectedApprovalLineAccount(approvalLineAccounts) {
      this.model.approvalLineAccounts = approvalLineAccounts;
    },
    async fetchAndInitStoredDraftData(targetPk) {
      const data = await this.fetchStoredDraftData(targetPk);
      this.onUpdateSelectedDraftDept(data.drftDeptCode);
      this.draftApprLineinitData = this.makeDraftApprLineInitData(data);
      this.model.initFromFetchData(data);
    },

    /**
     * 결제선 지정 인원 생성
     * @param aprvlLineList
     * @return {{approvalLine: *, draftDeptName: *, aprvlLineAccntNo: default.methods.searchForm.selectedPayAccount.code, draftLine: *, referrerLine: *}}
     */
    makeDraftApprLineInitData({ aprvlLineList, aprvlLineAccntNo, drftDeptName }) {
      const draftLine = aprvlLineList
        .filter((e) => e.aprvlLineType === aprvlLineType.DRAFT || e.prsntDeptYn == true)
        .filter((e) => e.accountId !== this.login.accountId.replace(".ke", ""))
        .sort((a, b) => a.aprvlOrder - b.aprvlOrder);

      // 21.3.19 재승인 요청 시 본인이 기안자에 2번 들어가는 현상으로 지정 인원 생성 시 배열의 처음 원소를 삭제 처리합니다.
      draftLine.shift();

      const approvalLine = aprvlLineList
        .filter((e) => e.aprvlLineType === aprvlLineType.APPROVAL && e.prsntDeptYn == false)
        .sort((a, b) => a.aprvlOrder - b.aprvlOrder);

      const referrerLine = aprvlLineList
        .filter((e) => e.aprvlLineType === aprvlLineType.REFERENCE)
        .sort((a, b) => a.aprvlOrder - b.aprvlOrder);

      return {
        aprvlLineAccntNo: aprvlLineAccntNo,
        draftDeptName: drftDeptName,
        draftLine,
        approvalLine,
        referrerLine,
      };
    },
    deleteConfirm() {
      this._showLayer(
        { content: "삭제를 진행하시겠습니까?", type: "confirm" },
        this.deleteTempDocs,
      );
    },
    async deleteTempDocs() {
      const data = {
        targetGubun: targetGubun.STANDARD,
        docNoList: [this.docNo],
      };
      const res = await ApiService.shared.delete(this.$apiPath.APRVL_DOC_LIST, {
        data,
      });

      if (!this.checkNormalResponse(res)) {
        return;
      }
      this.gotoList();
    },
    fileUploaded(files) {
      this.model.attachFileList = this.model.attachFileList.concat(files);
    },
    fileRemoved(itemToRemove) {
      this.model.attachFileList = this.model.attachFileList.filter((item) => item !== itemToRemove);
    },
  },
};
</script>
